//! Deprecate: Este componente sigue existiendo por si el redireccionamiento //
//! falla pero el componente correcto es "BrokerLeads"
<template>
  <div class="broker-lead-kanban-view">
    <v-row>
      <v-col md="12">
        <lead-kanban />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  components: {
    LeadKanban: () => import("@/components/lead/LeadKanban.vue")
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>
